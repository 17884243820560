import { STORAGE_KEY } from '@common/values';
import { CAN_USE_DOM } from '@lib/common-react/utils/environment';
import React, { useCallback, useEffect, useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SPLoading } from '@/components/commons';
import { useGetAllStoryGameSectionViewsQuery } from '@/operations/queries/home/useGetAllStoryGameSectionViews';
import { StoryGameSectionComponent } from '@/lib/common-react/components/storyGameHomeTab/StoryGameSectionComponent';
import { useRouter } from 'next/router';
import {
  getRawLocalStorageValueArrayByPrefix,
  isMobileDevice,
  safeJSONStringify,
} from '@/common/utils';
import {
  goPageForApp,
  goStoryGameDetailsForApp,
  onLogEventForApp,
} from '@/lib/webview';
import { logEventParams } from '@/customTypes/webview';
import { useCreateExposedSectionStoryMutation } from '@/operations/queries/home/__generated__/useCreateExposedSectionStory.generated';
import {
  touchStorygameThumbnail,
  viewStorygameThumbnail,
} from '@/lib/eventManager';

interface IStoryGameMainHomeContainer {
  tabId: number;
}
const StoryGameMainHomeContainer = ({ tabId }: IStoryGameMainHomeContainer) => {
  const router = useRouter();
  const { data, loading, refetch } = useGetAllStoryGameSectionViewsQuery({
    variables: {
      data: {
        tabId: tabId,
      },
    },
  });
  const [createExposedSectionStory] = useCreateExposedSectionStoryMutation();
  const thumbnailEventParams = useRef<logEventParams[] | undefined>(undefined);
  /**
   * 현재 플레이 중인 스토리들과 이전 플레이중인 스토리들을 비교하여 업데이트 여부를 확인하고,
   * 업데이트가 된 상태라면 refetch 를 호출하여 최신 탭 데이터를 가져옵니다.
   */
  const checkAndUpdatePlayingStories = useCallback(() => {
    if (!CAN_USE_DOM) {
      return;
    }

    const currentPlayingStories =
      safeJSONStringify(
        getRawLocalStorageValueArrayByPrefix(STORAGE_KEY.CURRENT_PLAY),
      ) ?? '[]';
    const prevPlayingStories = sessionStorage.getItem(
      STORAGE_KEY.STORY_GAME_TAB.PREV_PLAYING_STORIES,
    );

    if (!prevPlayingStories) {
      sessionStorage.setItem(
        STORAGE_KEY.STORY_GAME_TAB.PREV_PLAYING_STORIES,
        currentPlayingStories,
      );
      return;
    }

    const hasUpdate = currentPlayingStories !== prevPlayingStories;

    if (hasUpdate) {
      sessionStorage.setItem(
        STORAGE_KEY.STORY_GAME_TAB.PREV_PLAYING_STORIES,
        currentPlayingStories,
      );
      refetch();
    }
    thumbnailEventParams.current = [];
  }, [refetch]);

  // 해당 페이지에 다시 진입시에 플레이중인 스토리들이 업데이트 되어있다면 refetch를 합니다.
  // 진행형 섹션의 최신 데이터를 참조하기 위해서
  useEffect(() => {
    const carouselProgressSection = data?.find(
      (section) => section.uiType === 'CarouselProgress',
    );

    if (!carouselProgressSection) {
      return;
    }

    checkAndUpdatePlayingStories();
  }, [checkAndUpdatePlayingStories, data]);

  useEffect(() => {
    window.requestThumbnailEvent = requestThumbnailEvent;

    return () => {
      delete window.requestThumbnailEvent;
    };
  }, []);

  const requestThumbnailEvent = () => {
    thumbnailEventParams.current &&
      thumbnailEventParams.current.forEach((params) => {
        onLogEventForApp('view_storygame_thumbnail', params);
      });
  };

  const handleStoryItemClick = ({
    storyId,
    url,
    sectionId,
    isTestSection,
    sectionName,
    sectionIndex,
    storyName,
    storyBundleId,
  }: {
    storyId?: number;
    url?: string;
    sectionId?: number;
    isTestSection?: boolean;
    sectionName?: string;
    sectionIndex?: number;
    storyName?: string;
    storyBundleId?: number;
  }) => {
    if (storyId) {
      if (isMobileDevice()) {
        onLogEventForApp('touch_storygame_thumbnail', {
          section_name: sectionName || '',
          section_index: (sectionIndex || 0) + 1,
          story_name: storyName || '',
          story_id: storyId || '',
          story_bundle_id: storyBundleId || '',
          play_type: 'storygame',
        });
        goStoryGameDetailsForApp({
          storyId: storyId,
          ...(isTestSection &&
            sectionId && { queryParams: `testSectionId=${sectionId}` }),
        });
      } else {
        touchStorygameThumbnail({
          sectionName: sectionName || '',
          sectionIndex: (sectionIndex || 0) + 1,
          storyName: storyName || '',
          storyId: storyId?.toString() || '',
          storyBundleId: storyBundleId?.toString() || '',
        });
        router.push({
          pathname: `/story/${storyId}`,
          query: {
            ...(isTestSection && sectionId && { testSectionId: sectionId }),
          },
        });
      }
    }
    if (url) {
      isMobileDevice()
        ? goPageForApp({ link: url })
        : window.open(url, '_blank');
    }
  };

  const handleViewMoreClick = ({
    sectionId,
    sectionColumn,
    sectionIndex,
    tagName,
  }: {
    sectionId?: number;
    sectionColumn?: number;
    sectionIndex?: number;
    tagName?: string;
  }) => {
    const viewMoreUrl = `/more/storygame?sectionId=${sectionId}&tabId=${tabId}&sectionIndex=${
      (Number(sectionIndex) || 0) + 1
    }${sectionColumn !== undefined ? `&sectionColumn=${sectionColumn}` : ''}${
      tagName !== undefined ? `&tagName=${tagName}` : ''
    }`;

    tabId &&
      sectionId &&
      (isMobileDevice()
        ? goPageForApp({
            link: `${window.location.origin}/${
              router.locale ?? 'ko'
            }${viewMoreUrl}`,
          })
        : router.push(viewMoreUrl));
  };

  const handleStoryItemExposed = ({
    storyId,
    sectionId,
    isTestSection,
    sectionName,
    sectionIndex,
    storyName,
    storyBundleId,
  }: {
    storyId?: number;
    sectionId?: number;
    isTestSection: boolean;
    sectionName?: string;
    sectionIndex?: number;
    storyName?: string;
    storyBundleId?: number;
  }) => {
    if (isMobileDevice()) {
      const eventParams = {
        section_name: sectionName || '',
        section_index: (sectionIndex || 0) + 1, // index는 1부터 시작
        story_name: storyName || '',
        story_id: storyId || '',
        story_bundle_id: storyBundleId || '',
        play_type: 'storygame',
      };
      onLogEventForApp('view_storygame_thumbnail', eventParams);
      if (!Array.isArray(thumbnailEventParams.current)) {
        thumbnailEventParams.current = []; // hoook으로 초기화가 안됨
      }
      thumbnailEventParams.current.push(eventParams);
    } else {
      viewStorygameThumbnail({
        sectionName: sectionName || '',
        sectionIndex: (sectionIndex || 0) + 1, // index는 1부터 시작
        storyName: storyName || '',
        storyId: storyId?.toString() || '',
        storyBundleId: storyBundleId?.toString() || '',
      });
    }

    if (isTestSection && storyId && sectionId) {
      createExposedSectionStory({
        variables: {
          data: {
            storygameId: storyId,
            storygameSectionId: sectionId,
          },
        },
      });
    }
  };

  return (
    <>
      {loading ? (
        <SPLoading />
      ) : (
        <StoryGameSectionComponent
          data={data}
          onClickStoryItem={handleStoryItemClick}
          onClickViewMore={handleViewMoreClick}
          onStoryItemExposed={handleStoryItemExposed}
        />
      )}
    </>
  );
};

export default StoryGameMainHomeContainer;
